import { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../styles/blog.scss"

import React from "react"

const AgencyTempalte = ({ data }) => {
  const [activeTab, setActiveTab] = useState(
    data.allContentfulAgencyCategory.nodes[0].title
  )

  const uniqueTitles = new Set()

  const uniqueElements = data.allContentfulAgencyCategory.nodes
    .flatMap(e =>
      e?.ageny_experts?.flatMap(el =>
        el?.cityLocation?.flatMap(c =>
          c?.country?.flatMap(con =>
            el?.agencyCategory?.map(d => ({
              title: c?.title,
              dSlug: d?.slug,
              dTitle: d?.title,
              conSlug: con?.slug,
              cSlug: c?.citySlug,
            }))
          )
        )
      )
    )
    .filter(item => {
      const key = `${item?.title}-${item?.dSlug}`
      if (!uniqueTitles.has(key)) {
        uniqueTitles.add(key)
        return true
      }
      return false
    })

  return (
    <Layout>
      <SEO
        title={
          "Need a pro for your project? Dive in, read reviews, and find the best fit here."
        }
        description={
          "Discover top professionals for your project needs. Browse through detailed reviews, compare skills, and easily connect with the right expert– your project's success is just a click away!"
        }
      />
      <div className="container">
        <section className="blog-header">
          <Link to="/agencies">
            <p className="title-tag">/ Agency</p>
          </Link>
          <h1 className="page-title agency-pageTitle">
            Need a pro for your project? Dive in, read reviews, and find the
            best fit here.
          </h1>
        </section>
        <section>
          <div className="cdr-tab">
            <ul className="cdr-tab__list">
              {data.allContentfulAgencyCategory.nodes.map((el, i) => (
                <li
                  key={i}
                  className={`cdr-tab__item ${
                    activeTab === el?.title ? "active" : ""
                  }`}
                  onClick={() => setActiveTab(el.title)}
                >
                  {el.title}
                </li>
              ))}
            </ul>
          </div>
          <div className="cdr-tab__content">
            <div className="agency-cards">
              {uniqueElements.map((e, i) => (
                <div
                  key={i}
                  className={`agency-cards-item ${
                    activeTab === e?.dTitle ? "active" : ""
                  }`}
                >
                  <Link to={`/agencies/${e?.dSlug}/${e?.conSlug}/${e?.cSlug}`}>
                    {e?.title}
                  </Link>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="cdr-tab">
            <ul className="cdr-tab__list">
              {data.allContentfulAgencyCategory.nodes.map(c => (
                <>
                  <li
                    className={`cdr-tab__item ${
                      activeTab === c.title ? "active" : ""
                    }`}
                    onClick={() => setActiveTab(c.title)}
                  >
                    {c.title}
                  </li>
                  <div className="cdr-tab__content">
                    <div className="agency-cards">
                      {c.ageny_experts.map(el =>
                        el.cityLocation.map(c =>
                          c.country.map(con =>
                            el.agencyCategory.map(d => (
                              <div
                                className={`agency-cards-item ${
                                  activeTab === d.title ? "active" : ""
                                }`}
                              >
                                <Link
                                  to={`/agencies/${c.slug}/${con.slug}/${c.citySlug}`}
                                >
                                  {c.title}
                                </Link>
                              </div>
                            ))
                          )
                        )
                      )}
                    </div>
                  </div>
                </>
              ))}
            </ul>
          </div> */}
        </section>
      </div>
    </Layout>
  )
}

export default AgencyTempalte
export const pageQuery = graphql`
  query {
    allContentfulAgencyCategory {
      nodes {
        title
        slug
        ageny_experts {
          cityLocation {
            title
            citySlug
            country {
              title
              slug
            }
          }
          agencyCategory {
            title
            slug
          }
        }
      }
    }
  }
`
